import { Outlet, useLocation } from "react-router-dom";
import { Loading } from "./components/Loading";
import { Navbar } from "./components/Navbar";
import React, { useState } from "react";
import llama from '../assets/llama.png'
import turkey from '../assets/turkey.png'

export function Layout(props) {
  const { setAnimal, animal, page, setPage } = props;
  const location = useLocation();


  return (
    <div className={`flex flex-col w-full min-h-screen ${animal ? "bg-lightPurple": "bg-lightBlue"}`}>
      <Navbar setAnimal = {setAnimal} animal={animal} page={page} setPage={setPage}/>
      <div className="flex-grow grid lg:grid-cols-[30%,70%] h-full items-end justify-center">
        <img src={animal ? turkey : llama} />
        <Outlet/>
      </div>
    </div>
  );
}
