import download from '../../assets/download.png'

export function ShameList() {

  return (
    <div className="flex flex-col py-4 px-16 w-full h-full items-center justify-center text-center gap-16 font-jua">
      <h2 className ="text-6xl text-black">Add your name to the <span className="text-red hover:cursor-pointer" onClick={() => window.open("https://twitter.com/shame_token")}>$SHAME</span>list for loads of free airdrops</h2>
      <div className="flex flex-col gap-8 w-full items-center justify-center text-center">
        <div className = "flex flex-row gap-8 w-full items-center justify-center text-center">
          <button className= "bg-white border border-black rounded-lg text-red text-4xl py-2 px-4">verify twitter</button>
          <button className= "bg-white border border-black rounded-lg text-red text-4xl py-2 px-4">connect wallet</button>
        </div>
        <button className= "bg-red border border-black rounded-lg text-white text-4xl w-[20%] p-2 uppercase">Submit</button>
        <h2 className ="text-3xl text-black">
          Adding your name to the <span className="text-red hover:cursor-pointer" onClick={() => window.open("https://twitter.com/shame_token")}>$SHAME</span>list costs 1M <span className="text-red hover:cursor-pointer" onClick={() => window.open("https://twitter.com/shame_token")}>$SHAME</span> (to be burned)
        </h2>
      </div>
      <h2 className = "lg:flex lg:fixed lg:bottom-8 text-3xl">We need your twitter to publicly SHAME you if you ever dump your tokens...</h2>
    </div>
  )
}
