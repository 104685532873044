import llama from '../../assets/llama.png'
import turkey from '../../assets/turkey.png'
import download from '../../assets/download.png'

export function HomePage(props) {
  const { animal } = props;

  return (
    <div className="flex flex-col p-4 w-full h-full items-center justify-center text-center font-jua">
      <h1 className ="text-[200px] text-red font-bold">$SHAME</h1>
      <h2 className ="text-6xl text-black">Tired of being dumped on?</h2>
      <div className="hidden lg:flex lg:fixed lg:bottom-8 lg:right-12 lg:z-50 lg:hover:cursor-pointer lg:gap-4">
        <p className= "text-4xl text-red"> da fuq is $SHAME?</p>
        <img src={download} onClick={() => window.open("https://discord.gg/p77QeCs6qs")} class="w-[40px]"/>
      </div>
    </div>
  )
}
