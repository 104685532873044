import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation } from "react-router-dom";

import llama from '../../../assets/llama.png'
import turkey from '../../../assets/turkey.png'
import twitter from '../../../assets/twitter.png'
import telegram from '../../../assets/telegram.png'
import birdeye from '../../../assets/birdeye.png'
import dexscreener from '../../../assets/dexscreener.png'

import ShameList from '../../../pages/ShameList'
import ShameDrop from '../../../pages/ShameDrop'
import HomePage from '../../../pages/HomePage'

export function Navbar(props) {
  const { setAnimal, animal, page, setPage } = props;
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 999px)" });

  const toggleAnimal = () => {
    setAnimal(prevAnimal => !prevAnimal);
  }

  var currentURL = window.location.href;
  useEffect(() => {
    var urlParts = currentURL.split("/");
    var pageName = urlParts[urlParts.length - 1];
    setPage(pageName)
  }, [currentURL])


  return (
    <nav className={`w-full flex justify-between px-12 py-4 font-jua`}>
      <div className = "flex flex-row justify-center items-center text-center gap-4 text-2xl">
        <NavLink to="/" className={"flex-shrink-0 w-14 lg:w-20"}>
          <img src={animal ? llama : turkey} style={{height: '60px'}} alt="Llama Logo" onClick={toggleAnimal}/>
        </NavLink>
        <NavLink to="/shamelist" className={`${page === 'shamelist' ? 'bg-white' : ''} border border-black rounded-lg justify-center text-center items-center px-4 py-1`}>
          <p className = "text-red ">$SHAME<span className="text-black">list</span></p>
        </NavLink>
        <NavLink to="/shamedrop" className={`${page === 'shamedrop' ? 'bg-white' : ''} border border-black rounded-lg justify-center text-center items-center px-4 py-1`}>
          <p className = "text-red ">$SHAME<span className="text-black">drop</span></p>
        </NavLink>
      </div>
      <div className="hidden sm:flex gap-8 text-md">
        <ul className="flex space-x-10 justify-center items-center">
          <li>
            <img src={twitter} onClick={() => window.open("https://twitter.com/shame_token")} className={"flex-shrink-0 w-8 lg:w-10 hover:cursor-pointer"}/>
          </li>
          <li>
            <img src={telegram} onClick={() => window.open("https://discord.gg/p77QeCs6qs")} className={"flex-shrink-0 w-8 lg:w-10 hover:cursor-pointer"}/>
          </li>
          <li>
            <img src={dexscreener} onClick={() => window.open("https://discord.gg/p77QeCs6qs")} className={"flex-shrink-0 w-8 lg:w-10 hover:cursor-pointer"}/>
          </li>
          <li>
            <img src={birdeye} onClick={() => window.open("https://discord.gg/p77QeCs6qs")} className={"flex-shrink-0 w-8 lg:w-10 hover:cursor-pointer"}/>
          </li>
        </ul>
      </div>
    </nav>
  );
}
