import download from '../../assets/download.png'

export function ShameDrop() {
  return (
    <div className="flex flex-col py-4 px-16 w-full h-full items-center justify-center text-center gap-16 font-jua">
      <h2 className ="text-6xl text-black">Airdrop your token to our <span className="text-red">$SHAME</span>list and start SHAMING your users</h2>


    </div>
  )
}
