import { HomePage } from '../pages/HomePage';
import { ShameList } from '../pages/ShameList';
import { ShameDrop } from '../pages/ShameDrop';
import { NotFound } from '../pages/NotFound';
import React, { useState } from "react";

import { Layout } from '../Layout';
import { BrowserRouter, Routes as Switch, Route } from 'react-router-dom';

// import { RequireAuth } from './components/RequireAuth';

export function Routes() {
  const [animal, setAnimal] = useState(true);
  const [page, setPage] = useState("home");

  return (
    <BrowserRouter>
      <Switch>
        <Route element={<Layout animal = {animal} setAnimal = {setAnimal} page= {page} setPage = {setPage}/>}>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<HomePage animal = {animal}/>} />
          <Route path="/shameList" element={<ShameList animal = {animal} />} />
          <Route path="/ShameDrop" element={<ShameDrop animal = {animal} />} />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
